import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import AmountCell from './components/AmountCell';
import PaymentStatus from './components/PaymentStatus';
import DateCell from './components/DateCell';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import ActionButtonCell from './components/ActionButtonCell';
const getPaymentColumnsDefinition = (t) => ([
    {
        header: t('settings.tabs.subscription.payment_table.date'),
        accessorKey: 'date',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        aggregationFn: 'count',
        cell: tableRendererWrapper(DateCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.total'),
        accessorKey: 'total',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'center',
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.platform_fee'),
        accessorKey: 'platformFee',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.status'),
        accessorKey: 'status',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(PaymentStatus),
    },
    {
        header: t('settings.tabs.subscription.payment_table.subscription_plan'),
        accessorKey: 'subscriptionPlan',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(SubscriptionPlanCell),
    },
    {
        header: '',
        accessorKey: 'action_btn',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'right',
        },
        cell: tableRendererWrapper(ActionButtonCell),
    },
]);
const getSubscriptionPlanColumnsDefinition = () => ([]);
export { getSubscriptionPlanColumnsDefinition, getPaymentColumnsDefinition };
