import { PaymentStatusEnum, SubscriptionPlan, } from './types';
const PAYMENTS_MOCK_DATA = [
    {
        date: new Date('2022-06-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: SubscriptionPlan.Advanced,
        status: PaymentStatusEnum.InProgress,
    },
    {
        date: new Date('2022-05-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: SubscriptionPlan.Starter,
        status: PaymentStatusEnum.UnPaid,
    },
    {
        date: new Date('2022-04-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: SubscriptionPlan.Starter,
        status: PaymentStatusEnum.Paid,
    },
];
const PAYMENT_INFO_MOCK = {
    paymentDate: new Date('July 29, 2023'),
    paymentAmount: 99,
    paymentCurrency: '$',
    paymentPlan: SubscriptionPlan.Advanced,
};
const SUBSCRIPTION_PLANS_MOCK = [
    {
        id: 'STARTER_PLAN',
        title: 'Starter',
        description: 'Unlimited free trial: SPOT only',
        isRecommended: false,
        isFree: true,
        isAvailable: true,
        options: {
            limits: 'One portfolio',
            spotCopy: true,
            margin: false,
            futures: false,
            support: 'General support',
        },
    },
    {
        id: 'ADVANCED_PLAN',
        title: 'Advanced',
        description: 'Increase your profits with unlimited semi-manual trading',
        isRecommended: false,
        current: true,
        isAvailable: true,
        options: {
            limits: 'Four portfolio',
            spotCopy: true,
            margin: true,
            futures: false,
            support: 'General support',
        },
        currency: '$',
        fee: 49,
    },
    {
        id: 'PRO_PLAN',
        title: 'Pro',
        description: 'Increase your profits with unlimited semi-manual trading',
        isAvailable: false,
        options: {
            limits: 'Eight portfolio',
            spotCopy: true,
            margin: true,
            futures: true,
            support: 'Premuim support (24/7)',
        },
        currency: '$',
        fee: 99,
    },
];
export { PAYMENTS_MOCK_DATA, PAYMENT_INFO_MOCK, SUBSCRIPTION_PLANS_MOCK };
