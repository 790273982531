import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent } from '@icons/Logo.svg';
import { formatAmount } from '@src/shared/lib/Helpers';
import TooltipLabel from './TooltipLabel';
const EndrexIcon = styled(ReactComponent)(props => ({
    width: props.theme.baseSize * 4,
    height: props.theme.baseSize * 4,
}));
const PlatformBalanceContainer = styled.div(() => ({
    width: '100%',
}));
const PlatformInnerContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
    borderRadius: 6,
    padding: `${props.theme.spacing_sizes.m}px ${props.theme.spacing_sizes.xm}px`,
    backgroundColor: props.theme.customColors.input.backgroundDisabled,
}));
const PlatformText = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontWeight: 500,
}));
const PlatformBalance = ({ amount = 256.01, currency = '$', label = undefined, tooltipText = undefined, innerText = undefined, }) => (_jsxs(PlatformBalanceContainer, { children: [label || tooltipText ? (_jsx(TooltipLabel, { labelText: label || '', tooltipText: tooltipText || '' })) : null, _jsxs(PlatformInnerContainer, { children: [_jsx(EndrexIcon, {}), _jsxs(PlatformText, { children: [innerText ? _jsx("span", { children: innerText }) : null, amount && currency ? _jsx("span", { children: `${currency} ${formatAmount(amount)}` }) : null] })] })] }));
export default PlatformBalance;
